.intro{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40vh;
    grid-row: 6;
}

.intro-text{
    color: transparent;
    font-size: 4vh;
}

.banner{
    justify-content:center;
    align-items: center;
    display: grid;
    margin-top: 2%;
    height: 100%;
    grid-template-rows: 12% 12% 12% 12% 12% 40%;
    grid-template-columns: 97%;
    color: transparent;
    font-size: 11vh;
    font-weight: 500;
}




.row{
    display: flex;
    transition: 300ms ease-out;
}

.row-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.row-2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.row-3{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.row-4{
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
}

.row-2-alt{
    display: none;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.row-3-alt{
    display: none;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.row-4-alt{
    display: none;
    justify-content: right;
    align-items: center;
    height: 100%;
}

.row-5-alt{
    display: none;
    justify-content: right;
    align-items: center;
    height: 100%;
}




a{
    color: inherit;
    text-decoration: none;
}

a:hover{
    color: inherit;
}




.name{
    margin: 0 0 0 0;
}

.designer{
    display: flex;
    align-items: flex-start;
    margin: 0 0 0 10vw;
}

.designer-hyper{
    font-size: 1.1vh;
    margin-top: 3.4%;
    letter-spacing: 4px;
}

.coder{
    display: flex;
    padding: 0 15vw 0 0;
}

.artist{
    display: flex;
}

.artist-hyper{
    font-size: 1.1vh;
    padding: 0 0.5vh 2.5vh 0;
    letter-spacing: 4px;
    align-self: flex-end;
}



.text-hover{
    color: inherit;
    background: linear-gradient(0deg, transparent, transparent);
    transition: 500ms;
    background-position: left;
    background-size: 0% 72%;
}

.text-hover:hover{
    color: #111212;
    background: linear-gradient(0deg, #fff, #fff);
    background-size: 100% 72%;
    background-repeat: no-repeat;
    background-position: left;
}

.coder-text-hover{
    color: inherit;
    background: linear-gradient(0deg, transparent, transparent);
    background-size: 0% 72%;
    background-repeat: no-repeat;
    background-position: left;
    transition: 500ms;
}

.coder-text-hover:hover{
    color: #111212;
    background: linear-gradient(0deg, #fff, #fff);
    background-size: 68% 72%;
    background-repeat: no-repeat;
    background-position:left;
}


.coder-coming-soon{
    font-size: 1.1vh;
    letter-spacing: 4px;
    padding: 2vh 0 0 0;
}

.artist-coming-soon{
    font-size: 1.1vh;
    letter-spacing: 4px;
    padding: 2vh 0 0 1vh;
}




@media screen and (max-width: 1600px) {
    .row-2{
        display: none;
    }

    .row-3{
        display: none;
    }

    .row-4{
        display: none;
    }

    .row-2-alt{
        display: flex;
        justify-content: right;
    }
    
    .row-3-alt{
        display: flex;
        justify-content: left;
    }
    
    .row-4-alt{
        display: flex;
        justify-content: space-between;
    }
    
    .row-5-alt{
        display: flex;
        justify-content: right;
    }




    .coder{
        display: flex;
        padding: 0 0 0 15vw;
    }

    .coder-coming-soon{
        font-size: 1.1vh;
        letter-spacing: 4px;
        padding: 2vh 0 0 0.5vh;
    }

    .artist-coming-soon{
        font-size: 1.1vh;
        letter-spacing: 4px;
        padding: 2vh 0 0 1vh;
    }




    .coder-text-hover{
        background-size: 0% 72%;
        background-position: right;
    }
    
    .coder-text-hover:hover{
        background-size: 75% 72%;
        background-position: right;
    }
}

@media screen and (max-width: 1100px){
    .intro{
        margin-bottom: 10vh;
    }

    .banner{
        font-size: 5vh;
        height: 55%;
        margin: 3%;
        grid-template-rows: 12% 12% 12% 12% 12% 100%;
    }




    .designer-hyper{
        font-size: 0.9vh;
        margin-top: 1vh;
        letter-spacing: 2px;
    }

    .artist-hyper{
        font-size: 0.9vh;
        padding: 0 0.5vh 1.2vh 0;
        letter-spacing: 2px;
        align-self: flex-end;
    }

    .coder-coming-soon{
        font-size: 0.9vh;
        letter-spacing: 2px;
        padding: 1vh 0 0 0.5vh;
    }

    .artist-coming-soon{
        font-size: 0.9vh;
        letter-spacing: 2px;
        padding: 1vh 0 0 0.5vh;
    }




    .coder-text-hover{
        background-size: 0% 72%;
    }
    
    .coder-text-hover:hover{
        background-size: 80% 72%;
    }
}