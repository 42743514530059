.scrollable-about{
    height: 100%;
    display: grid;
    grid-template-columns: fit-content(100vh) auto;
    grid-template-rows: 22vh 63vh auto auto;
    padding: 0 1.5vw;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollable-about::-webkit-scrollbar{
    display: none;
}




.about-title{
    justify-content:center;
    align-items: center;
    display: grid;
    padding-top: 2%;
    height: 100%;
    grid-template-rows: 50% 50%;
    grid-template-columns: 100%;
    font-size: 11vh;
    font-weight: 500;
    color: #ffffff00;
}


.about-line-divider{
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #ffffff00;
    position: relative;
    align-self: left;
    margin: 3vh 0 1vh 0;
}

.top-line{
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #ffffff00;
    position: relative;
    align-self: left;
    margin: 4vh 0 2vh 0;
}




.about-row{
    display: flex;
    transition: 300ms ease-out;
}

.about-row-1, .about-row-2{
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
}




.about-intro{
    grid-column: 2;
    grid-row: 2;
    padding: 12vh 14vh 0 3vh;
}

.intro-large{
    color: #ffffff00;
    font-size: 5.5vh;
    padding-bottom: 1vh;
}

.intro-small{
    color: #ffffff00;
    font-size: 2vh;
    margin-bottom: 10vh;
}

.intro-arrow{
    display: flex;
    padding-bottom: 1vh;
    grid-column: 2;
    grid-row: 1;
}




.about-section-title-services{
    color: #ffffff00;
    font-size: 9vh;
    grid-column: 1;
    grid-row: 3;
}

.about-services{
    grid-column: 2;
    grid-row: 3;
    padding: 0 14vh 0 3vh;
}




.about-section-title-employment{
    color: #ffffff;
    font-size: 9vh;
    grid-column: 1;
    grid-row: 4;
}

.about-employment{
    grid-column: 2;
    grid-row: 4;
    padding: 0 14vh 0 3vh;
}



.about-buttons-container{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 2.5vw;
    bottom: 17.5vh;
}

.about-scroll-button{
    border: #fff solid 1px;
    background-color: #111212;
    color: #fff;
    font-size: 50px;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    color: #fff;
    transition: 200ms;
    padding: 0;
    margin-top: 10px;
}

.about-scroll-button:hover{
    background-color:#fff;
    color: #111212;
}



@media screen and (max-width: 1820px) {
    .scrollable-about{
        grid-template-columns: fit-content(70vh) auto;
        grid-template-rows: 18vh 67vh auto;
        padding: 0 1.5vw;
    }

    .about-intro{
        padding: 15vh 5vh 0 3vh;
    }

    .about-title{
        font-size: 8vh;
    }

    .intro-large{
        font-size: 3.5vh;
    }
    
    .intro-small{
        font-size: 1.5vh;
    }
}

@media screen and (max-width: 1200px){
    .scrollable-about{
        grid-template-columns: 100%;
        grid-template-rows: 12vh 73vh auto;
        padding: 0 1.5vw;
    }


    

    .about-intro{
        grid-column: 1;
        grid-row: 2;
        align-self: flex-end;
        padding: 1vh 35vw 1vh 1vw;
    }

    .about-title{
        font-size: 5vh;
    }

    .intro-large{
        font-size: 3.5vh;
    }
    
    .intro-small{
        font-size: 1.5vh;
        margin-bottom: 5vh;
    }

    .intro-arrow{
        display: flex;
        padding-bottom: 1vh;
        grid-column: 1;
        grid-row: 1;
        color: #ffffff00;
    }



    .about-section-title-services{
        font-size: 5vh;
        justify-self: right;
    }

    .about-services{
        grid-column: 1;
        grid-row: 3;
        padding: 5.5vh 35vw 0 1vw;
    }


    .about-section-title-employment{
        font-size: 5vh;
        justify-self: right;
    }

    .about-employment{
        grid-column: 1;
        grid-row: 4;
        padding: 5.5vh 35vw 0 1vw;
    }
}

@media screen and (max-width: 1000px) {
    .about-buttons-container{
        display: none;
    }
}