.footer{
    font-size: 1.3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 6vw 0 6vw;
    transition: 300ms ease-out;
    letter-spacing: 2px;
}

.footer-menu{
    justify-content: space-between;
    display: flex;
    width: 15%;
}

.footer-hamburger{
    display: none;
    justify-content: center;
    align-items: center;
    height: 40px;
    flex-direction: column;
    margin-right: 2%;
    cursor: pointer;
}




.footer-text{
    color: transparent;
}

.footer-info{
    justify-content: space-between;
    display: flex;
    width: 55%;
}

.footer-info-mobile{
    display: none;
    justify-content: space-between;
    width: 55%;
}





.link-underline::before{
    content: "";
    position: relative;
    display: flex;
    width: 100%;
    height: 1px;
    bottom: -1.7vh;
    left: 0;
    background-color: var(--underline-colour);
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s ease;
}

.link-underline:hover::before {
    transform: scaleX(1);
}





.hamburger-line{
    width: 30px;
    height: 0px;
    border-bottom: 1px solid transparent;
    position: relative;
}

.hamburger-line-0{
    margin-bottom: 8px;
}

.hamburger-line-1{
    opacity: 1;
}

.hamburger-line-2{
    margin-top: 8px;
}





@media screen and (max-width: 1500px) {
    .footer{
        font-size: 1vh;
    }



    
    .footer-menu{
        display: none;
    }

    .footer-hamburger{
        display: flex;
    }




    .footer-info{
        display: none;
    }
    
    .footer-info-mobile{
        display: flex;
    }
}

@media screen and (max-width: 1100px) {
    .footer{
        font-size: 0.9vh;
    }




    .footer-menu{
        display: none;
    }

    .footer-hamburger{
        display: flex;
    }




    .footer-info{
        display: none;
    }

    .footer-info-mobile{
        display: flex;
    }
}