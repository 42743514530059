.scrollable{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollable::-webkit-scrollbar{
    display: none;
}




.title{
    justify-content:center;
    align-items: center;
    display: grid;
    margin-top: 2%;
    height: 40%;
    grid-template-rows: 33% 33% 33%;
    grid-template-columns: 97%;
    font-size: 11vh;
    font-weight: 500;
    color: #11121200;
}




.title-row{
    display: flex;
    transition: 300ms ease-out;
}

.title-row-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.title-row-2, .title-row-3{
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
}

.title-row-1-alt, .title-row-3-alt, .title-row-4-alt{
    display: none;
    align-items: center;
    justify-content: left;
    height: 100%;
}

.title-row-2-alt{
    display: none;
    align-items: center;
    height: 100%;
    justify-content: right;
}


.title-line-divider{
    width: 97vw;
    height: 0px;
    border-bottom: 1px solid transparent;
    position: relative;
    align-self: center;
    margin: 1vh 0;
}




.title-name{
    margin: 0 0 0 0;
}

.title-title{
    display: flex;
    align-items: flex-start;
    margin: 0 0 0 10vw;
}

.title-hyper{
    font-size: 1.1vh;
    margin-top: 3.4%;
    letter-spacing: 4px;
}




.gallery-section{
    display: grid;
    padding: 0 1.5vw 1.5vh 1.5vw;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2vh;
    margin-top: 1vh;
}

.gallery-item{
    aspect-ratio: 0.9;
    opacity: 0;
}

.gallery-image{
    border: #111212 solid 1px;
    width: 100%;
    aspect-ratio: 1;
    display: flex;
}

.gallery-text{
    display: flex;
    justify-content: space-between;
    font-size: 1.2vh;
    letter-spacing: 2px;
    padding-top: 5px;
}




.buttons-container{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 2.5vw;
    bottom: 17.5vh;
}

.scroll-button{
    border: #111212 solid 1px;
    background-color: #ffffff;
    font-size: 50px;
    border-radius: 50%;
    color: #111212;
    display: flex;
    cursor: pointer;
    transition: 200ms;
    padding: 0;
    margin-top: 10px;
}

.scroll-button:hover{
    background-color:#111212;
    color: #fff;
}




@media screen and (max-width: 2200px) {
    .title{
        height: 48%;
        grid-template-rows: 25% 25% 25% 25%;
    }

    .title-row-1, .title-row-2, .title-row-3, .title-row-4{
        display: none;
    }

    .title-row-1-alt, .title-row-2-alt, .title-row-3-alt, .title-row-4-alt{
        display: flex;
    }
}

@media screen and (max-width: 1100px){
    .title{
        grid-template-rows: 25% 25% 25% 25%;
        height: 25%;
        font-size: 5vh;
        margin: 3%;
    }

    .title-hyper{
        font-size: 0.9vh;
        margin-top: 1vh;
        letter-spacing: 2px;
    }

    .title-line-divider{
        width: 95vw;
        margin: 0 0 1vh 0;
    }

    

    .gallery-section{
        padding: 0 2.5vw;
    }

    .gallery-text{
        font-size: 1vh;
    }

    .buttons-container{
        display: none;
    }
}