.about-container{
    background-color: #111212;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.about-title-container{
    height: 85%;
}

.footer-container{
    justify-content: center;
    display: flex;
    height: 15%;
}

.about-line{
    width: 97vw;
    height: 0px;
    border-bottom: 1px solid #ffffff00;
    position: absolute;
}

.about-menu-container{
    display: flex;
    flex-direction: column;
    height: 0%;
    overflow: hidden;
    width: 95vw;
    font-size: 6vh;
    align-self: center;
}

.about-menu-line{
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #fff;
    position: relative;
    align-self: center;
    margin-bottom: 1vh;
}

.about-menu-line-divider{
    width: 0%;
    height: 0px;
    border-bottom: 1px solid #ffffff00;
    position: relative;
    align-self: left;
    margin: 1vh 0;
}

.about-menu-text{
    padding: 1vh;
    display: flex;
    color: #fff;
}

.about-menu-text:hover{
    background-color: #fff;
    color: #111212;
    transition: 300ms ease-out;
}


.about-menu-coming-soon{
    font-size: 1.1vh;
    letter-spacing: 4px;
    padding: 1.3vh 0 0 1vh;
    align-self: flex-start;
}




@media screen and (max-width: 1100px){
    .about-line{
        width: 95vw;
    }

    .about-menu-coming-soon{
        font-size: 0.9vh;
        letter-spacing: 2px;
        padding: 1.3vh 0 0 1vh;
        align-self: flex-start;
    }
}