.container{
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.video-container{
    position: absolute;
}

.banner-container{
    height: 85%;
}

.footer-container{
    justify-content: center;
    display: flex;
    height: 15%;
}

.line{
    width: 97vw;
    height: 0px;
    border-bottom: 1px solid #ffffff00;
    position: absolute;
}

.menu-container{
    display: flex;
    flex-direction: column;
    height: 0%;
    overflow: hidden;
    width: 95vw;
    font-size: 6vh;
    align-self: center;
}

.menu-line{
    width: 100%;
    height: 0px;
    border-bottom: 1px solid #ffffff;
    position: relative;
    align-self: center;
    margin-bottom: 1vh;
}

.menu-line-divider{
    width: 0%;
    height: 0px;
    border-bottom: 1px solid #ffffff00;
    position: relative;
    align-self: left;
    margin: 1vh 0;
}

.menu-text{
    padding: 1vh;
    display: flex;
    color: #ffffff;
}

.menu-text:hover{
    background-color: #ffffff;
    color: #111212;
    transition: 300ms ease-out;
}

.menu-coming-soon{
    font-size: 1.1vh;
    letter-spacing: 4px;
    padding: 1.3vh 0 0 1vh;
    align-self: flex-start;
}

@media screen and (max-width: 1100px){
    .line{
        width: 95vw;
    }

    .menu-coming-soon{
        font-size: 0.9vh;
        letter-spacing: 2px;
        padding: 1.3vh 0 0 1vh;
        align-self: flex-start;
    }
}