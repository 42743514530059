.projects-intro{
    position: fixed;
    height: 40vh;
    width: 40vh;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.scrollable-projects{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 25vh auto auto;
    padding: 0 1.5vw;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.scrollable-projects::-webkit-scrollbar{
    display: none;
}




.projects-header-container{
    display: flex;
    width: 100%;
    grid-row: 1;
    justify-content: space-between;
    align-items: center;
    padding: 1vh 1vw 0 0
}

.projects-title{
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 11vh;
    font-weight: 500;
    color: #ffffff;
}

.projects-back{
    border: #fff solid 1px;
    background-color: #111212;
    color: #fff;
    font-size: 7.5vh;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    color: #fff;
    transition: 200ms;
    padding: 0;
}

.projects-back:hover{
    background-color:#fff;
    color: #111212;
}

.projects-text{
    color: #ffffff00;
    font-size: 3vh;
    padding: 1vh 10vw;
}




.projects-buttons-container{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 2.5vw;
    bottom: 17.5vh;
}

.projects-scroll-button{
    border: #fff solid 1px;
    background-color: #111212;
    color: #fff;
    font-size: 6vh;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    color: #fff;
    transition: 200ms;
    padding: 0;
    margin-top: 10px;
}

.projects-scroll-button:hover{
    background-color:#fff;
    color: #111212;
}



.projects-section{
    display: grid;
    padding: 1.5vh 10vw;
    grid-template-columns: 50% 50%;
    gap: 2vh;
}

.projects-image{
    width: 100%;
    height: fit-content;
    display: flex;
}

.image-span{
    grid-column: 1 / 3;
}



@media screen and (max-width: 1000px){
    .scrollable-projects{
        grid-template-rows: 14vh auto auto;
        padding: 0 1.5vw;
    }


    .projects-header-container{
        padding: 1vh 3vw 0 0.5vh;
    }

    .projects-title{
        font-size: 5.5vh;
    }

    .projects-back{
        font-size: 4vh;
    }

    .projects-text{
        font-size: 2vh;
        padding: 0 2vw;
    }



    .projects-section{
        grid-template-columns: 100%;
        padding: 1.5vh 1.5vw;
    }

    .image-span{
        grid-column: 1;
    }



    .projects-buttons-container{
        display: none;
    }
}